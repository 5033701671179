<template>
  <div id="personal">
    <header-bar title="个人中心" />
    <ul>
      <li>
        <label>姓名</label>
        <p>{{ userInfo.truename }}</p>
      </li>
      <li>
        <label>联系电话</label>
        <p>{{ userInfo.mobile }}</p>
      </li>
      <li>
        <label>用户名</label>
        <p>{{ userInfo.username }}</p>
      </li>
    </ul>
    <ul>
      <li @click="$router.push({ name: 'updatePassword' })">
        <label>修改密码</label>
        <img class="arrow" src="../assets/images/arrow_r.png" alt="">
      </li>
    </ul>

    <footer @click="isShowQuitePopup = true">退出登录</footer>
    <bottom-popup
      title="确认退出登录？"
      icon="quite_icon"
      :is-show="isShowQuitePopup"
      :has-cancel-btn="true"
      @clickCancel="isShowQuitePopup = false"
      @clickConfirm="clickConfirm"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import HeaderBar from '../components/HeaderBar/index';
import BottomPopup from '@/components/BottomPopup/index';
export default {
  name: 'Personal',
  components: { BottomPopup, HeaderBar },
  props: [],
  data() {
    return {
      isShowQuitePopup: false
    };
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {},
  created() {
    console.log(this.userInfo);
  },
  mounted() {},
  methods: {
    clickConfirm() {
      this.$store.dispatch('userInfo/logout');
      this.$router.push({ name: 'login' });
    }
  }

};
</script>

<style lang="scss" scoped>
#personal{
  ul {
    margin-bottom: 14px;
    li{
      margin-bottom: 2px;
      background: #fff;
      padding: 0 32px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 100px;
      line-height: 100px;
      p{
        font-size: 24px;
      }
      .arrow{
        width: 40px;
      }
    }
  }
  footer{
    width: 100%;
    height: 100px;
    line-height: 100px;
    text-align: center;
    color: $color-primary;
    background: #fff;
    position: fixed;
    left: 0;
    bottom: 0;
  }
}
</style>
